import React from 'react';
import { FluidObject } from 'gatsby-image';
import { Content, LayoutDefault, Paragraph, Subtitle } from '@src/ui';
import { Link } from 'gatsby';

interface Props {
  image: FluidObject | undefined;
}

export const PageOsteopathie: React.FC<Props> = props => (
  <LayoutDefault
    splashImage={props.image}
    title="L'ostéopathie animale - Isère et Savoie"
    description="L'ostéopathie animale pour chien, chat et NAC. Médecines complémentaires : ostéopathie animale, phytothérapie, homéopathie, entre Chambéry et Grenoble."
  >
    <Content first>
      <Paragraph
        title="L'ostéopathie animale : pour qui ? pour quoi ?"
        hLevel={1}
      >
        L&apos;ostéopathie est une technique manuelle apparue pour la première
        fois au début du 19ieme siècle, ayant pour objectif de rétablir la
        mobilité physiologique de l&apos;ensemble des structures du corps
        (articulations, viscères, fascias etc.…). Une altération de la mobilité
        de l&apos;organisme peut avoir des conséquences sur son bon
        fonctionnement et les compensations mises en place peuvent être à terme
        à l&apos;origine de douleurs.
      </Paragraph>
    </Content>

    <Content alt>
      <Paragraph
        title="Comment se déroule une consultation d'ostéopathie animale ?"
        hLevel={2}
      >
        <p>
          Une consultation dure entre 30 minutes et 1 heure. Elle se structure
          toujours de la façon suivante :
        </p>
        <br />
        <ul>
          <li>
            <strong>Un entretien</strong> pour identifier précisément votre
            demande et la ou les gênes que présente votre animal
          </li>
          <li>
            <strong>Un examen clinique</strong> classique de votre animal afin
            d&apos;identifier clairement la pathologie en cause, voir ensemble
            ce que l&apos;on peut mettre en place et/ou vous orienter chez votre
            vétérinaire traitant pour effectuer des examens complémentaires
          </li>
          <li>
            <strong>Un examen palpatoire ostéopathique</strong> pour déterminer
            les zones de restriction de mobilité, de tension ou de douleur
          </li>
          <li>
            <strong>Le traitement</strong> des restrictions par des
            manipulations douces (aucun « cracking »), en adaptant
            l&apos;approche en fonction de votre animal
          </li>
          <li>
            <strong>Un débriefing</strong> détaillé avec vous de ce qui a été
            traité et de l&apos;évolution attendue
          </li>
          <li>
            Une mise en place de <strong>traitements complémentaires</strong> si
            nécessaire et préconisations pour la suite (repos, visite de
            contrôle...)
          </li>
          <li>
            <strong>Un compte rendu</strong> détaillé est rédigé pour votre
            vétérinaire s&apos;il le souhaite.
          </li>
        </ul>
      </Paragraph>
    </Content>

    <Content>
      <Paragraph
        title="Quand consulter son vétérinaire ostéopathe ?"
        hLevel={2}
      >
        <p>
          Les médecines alternatives peuvent être utilisées seules ou en
          complément des traitements classiques. Leurs applications sont
          nombreuses, en voici quelques exemples :
        </p>
        <br />
        <Subtitle hLevel={3}>Pour les pathologies chroniques</Subtitle>
        <br />
        <ul>
          <li>
            <strong>En accompagnement</strong> : pour aider le corps de votre
            animal à supporter les effets secondaires de traitements lourds
            (corticothérapie, chimio, immunosuppresseurs, ...)
          </li>
          <li>
            <strong>Pour les seniors</strong> : douleurs arthrosiques, baisses
            cognitives, anxiété, toux chronique...
          </li>
          <li>
            <strong>Comportement</strong> : agitation nocturne, stress,
            conflits, destructions lors des absences..
          </li>
          <li>Dysplasie, séquelles d&apos;accidents</li>
          <li>
            <strong>Maladies inflammatoires</strong> <strong>chroniques</strong>{' '}
            : MICI, gastrites, ...
          </li>
          <li>
            <strong>Problèmes dermatologiques</strong> : Atopie, prurit
            inexpliqué, otites récidivantes, ...
          </li>
          <li>
            <strong>Accompagnement des maladies métaboliques</strong> (diabète,
            hypo ou hyperthyroidie, hypocorticisme, ...) : en complément du
            traitement médical classique
          </li>
          <li>
            <strong>Boiteries ou douleurs chroniques</strong> (après élimination
            des autres causes lésionnelles par votre vétérinaire)
          </li>
          <li>
            <strong>En soutient lors de cancer</strong>
          </li>
        </ul>

        <br />
        <Subtitle hLevel={3}>Pour les pathologies récurrentes</Subtitle>
        <p>Cystites inflammatoires, ulcères gastriques, coryza.</p>

        <br />
        <Subtitle hLevel={3}>Pour les problèmes aigus</Subtitle>
        <br />
        <ul>
          <li>
            <strong>Douleurs aiguës</strong> : boiterie, trauma, douleurs de
            dos, entorses, douleurs neurologiques
          </li>
          <li>
            Soutien du système immunitaire lors de{' '}
            <strong>pathologie infectieuse</strong> (coryza, toux du chenil,
            ...)
          </li>
          <li>
            En <strong>post-opératoire</strong>
          </li>
        </ul>

        <br />
        <Subtitle hLevel={3}>Accompagnement des chiens de sport</Subtitle>
        <br />
        <ul>
          <li>
            <strong>Amélioration des performances sportives</strong>
          </li>
          <li>
            <strong>Défauts ou mauvais appuis</strong> lors de l&apos;effort
            (traction assymétrique, foulées irrégulières)
          </li>
          <li>
            <strong>Inconfort</strong> lors de l&apos;effort intense (refus de
            traction, galop trop court...)
          </li>
        </ul>

        <br />
        <Subtitle hLevel={3}>En prévention</Subtitle>
        <br />
        <ul>
          <li>
            <strong>Chiot</strong> : si la mise bas a été compliquée
          </li>
          <li>
            Lors de la <strong>croissance</strong> : pour permettre un
            développement harmonieux et une bonne posture
          </li>
          <li>
            Favoriser <strong>le bon mouvement</strong> du corps pour prévenir
            les compensations et les douleurs quand le chien sera plus âgé
          </li>
        </ul>
        <br />
        <p>
          En cas de doute n&apos;hésitez pas à{' '}
          <Link to="/contact/">me contacter</Link> et nous en discuterons
          ensemble.
        </p>
        <p>
          <strong>
            Attention : les traitements naturels ne se substituent pas aux
            traitements médicaux et aux suivis effectués par votre vétérinaire
            traitant.
          </strong>
        </p>
      </Paragraph>
    </Content>

    <Content alt>
      <Paragraph title="L'ostéopathie pour les chiens de sport" hLevel={2}>
        Les sports canins se sont énormément développés ces dernières années,
        pour le plus grand plaisir de nos compagnons à 4 pattes. Agility,
        canicross, canitrail, cani-vtt, man-trailing, ring… les possibilités
        sont nombreuses et variées. Mais attention, même si votre chien est
        toujours très motivé pour sortir et semble ne jamais fatiguer, les
        contraintes sur son organisme au cours de l&apos;effort peuvent être
        importantes selon l&apos;intensité de la pratique sportive et son
        entrainement. Des douleurs ou des gênes peuvent alors apparaître et
        perturber le bon déroulement de vos sorties. Votre chien peut parfois
        très bien s&apos;adapter autour de ces raideurs et ne montrer aucun
        signe... jusqu&apos;à l&apos;arrivée de l&apos;arthrose. Alors pour
        pouvoir profiter longtemps de ces moments de complicité il est important
        de structurer correctement l&apos;entrainement de votre chien et de
        vérifier régulièrement que toutes les structures de son corps bougent
        harmonieusement avec une séance d&apos;ostéopathie. Vous pouvez vous
        aussi prendre de soin de lui à la maison avec des étirements et des
        massages, n&apos;hésitez à me demander si vous souhaitez que je vous
        montre quelques exemples lors de la consultation.
      </Paragraph>
    </Content>
  </LayoutDefault>
);
