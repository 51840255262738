import React, { FormEvent, useState } from 'react';
import { Title } from '@src/ui';
import styles from './contact.module.scss';
import { trackEvent } from '@src/services/analytics';
import { encode } from '@src/services/form';

export const FormContact: React.FC = () => {
  const [state, setState] = useState<
    'display' | 'error' | 'loading' | 'submitted'
  >('display');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    console.log('submit', e);
    trackEvent('Contact', 'Submit');
    setState('loading');

    setTimeout(() => {
      fetch('/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'contact',
          email,
          phone,
          name,
          message,
        }),
      })
        .then(handleSuccess)
        .catch(handleError);
    }, 1000);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSuccess = (): void => {
    trackEvent('Contact', 'Success');
    setState('submitted');
    setTimeout(() => setState('display'), 10000);
  };

  const handleError = (): void => {
    trackEvent('Contact', 'Error');
    setState('error');
    setTimeout(() => setState('display'), 10000);
  };

  let modifiers = '';
  if (state === 'submitted') modifiers = ` ${styles._submitted}`;
  if (state === 'error') modifiers = ` ${styles._error}`;
  if (state === 'loading') modifiers = ` ${styles._loading}`;

  return (
    <section className={styles.container}>
      <Title dark hLevel={2}>
        Un rendez-vous, une question ? Contactez le Dr Gaëlle Morat
      </Title>

      <div className={styles.content}>
        <div className={`${styles.contact} ${modifiers}`}>
          <form
            name={'contact'}
            method="POST"
            netlify-honeypot="bot-field"
            data-netlify="true"
            className={styles.form}
            onSubmit={handleSubmit}
          >
            <div className={styles.infos}>
              <div className={styles.fieldEmail}>
                <label htmlFor="email" className={styles.label}>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className={`${styles.input} ${
                      email.length > 0 ? styles.filled : ''
                    }`}
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    required
                    minLength={3}
                  />
                  <span className={styles.labelSpan}>Votre email</span>
                </label>
              </div>
              <div className={styles.fieldPhone}>
                <label htmlFor="phone" className={styles.label}>
                  <input
                    id="phone"
                    name="phone"
                    type="tel"
                    className={`${styles.input} ${
                      phone.length > 0 ? styles.filled : ''
                    }`}
                    onChange={e => setPhone(e.target.value)}
                    value={phone}
                    required
                    minLength={10}
                  />
                  <span className={styles.labelSpan}>Votre téléphone</span>
                </label>
              </div>
              <div className={styles.fieldName}>
                <label htmlFor="name" className={styles.label}>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    className={`${styles.input} ${
                      name.length > 0 ? styles.filled : ''
                    }`}
                    onChange={e => setName(e.target.value)}
                    value={name}
                    required
                    minLength={3}
                  />
                  <span className={styles.labelSpan}>Votre nom</span>
                </label>
              </div>
            </div>

            <div className={styles.message}>
              <div className={styles.fieldMessage}>
                <label htmlFor="message" className={styles.label}>
                  <textarea
                    id="message"
                    name="message"
                    className={`${styles.textarea} ${
                      message.length > 0 ? styles.filled : ''
                    }`}
                    onChange={e => setMessage(e.target.value)}
                    required
                    minLength={5}
                    value={message}
                  />
                  <span className={styles.labelSpan}>Votre message</span>
                </label>
                <div className={styles.spam}>
                  A noter que mes mails tombent parfois dans les spams. Pensez à
                  bien les vérifier et n&apos;hésitez pas à me rappeler en cas
                  de problème.
                </div>
              </div>
            </div>
            <div className={styles.hidden}>
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
            </div>
            <div className={styles.submit}>
              <button className={styles.submitButton} type="submit">
                Envoyer
              </button>
            </div>
          </form>
          <div className={styles.loaderContainer}>
            <div className={styles.loader}>
              <svg width="38" height="38" viewBox="0 0 38 38" stroke="#fff">
                <g fill="none" transform="translate(1 1)" strokeWidth="2">
                  <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                  <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 18 18"
                      to="360 18 18"
                      dur="1s"
                      repeatCount="indefinite"
                    />
                  </path>
                </g>
              </svg>
            </div>
          </div>
          <div className={styles.success}>
            <div className={styles.successTitle}>Message envoyé !</div>
            <div className={styles.successText}>
              Merci, je vous réponds au plus vite.
              <br />
              Pensez à bien vérifier vos spams.
            </div>
          </div>
          <div className={styles.error}>
            <div className={styles.errorTitle}>Une erreur est survenue !</div>
            <div className={styles.errorText}>
              Désolé, il y a eu un problème lors de l&apos;envoi du message.
              <br />
              N&apos;hésitez pas à me contacter par téléphone ou par email.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
