import React from 'react';
import { Link } from 'gatsby';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import { config } from '@src/ui';
import styles from './knowledge.module.scss';

type Props = {
  title: string;
  link?: string;
  image?: FluidObject;
};

export const Knowledge: React.FC<Props> = ({ title, image, link }) => {
  const picturePrefix = `${config.title} - ${config.subtitle} - `;

  const content = (
    <figure className={styles.knowledge}>
      {image && (
        <GatsbyImage
          className={styles.image}
          fluid={image}
          alt={picturePrefix + title}
        />
      )}
      <figcaption>
        <h3 className={styles.title}>{title}</h3>
      </figcaption>
    </figure>
  );

  return link ? (
    <Link className={styles.link} to={link}>
      {content}
    </Link>
  ) : (
    content
  );
};
