import React from 'react';
import { Link } from 'gatsby';
import { Subtitle } from '@src/ui';
import styles from './department.module.scss';

type Props = {
  name: string;
  slug: string;
};

export const Department: React.FC<Props> = ({ name, slug }: Props) => {
  return (
    <Subtitle hLevel={3}>
      <Link
        className={styles.link}
        to={`/secteur/prendre-rendez-vous-veterinaire-osteopathe-${slug}/`}
      >
        {name}
      </Link>
    </Subtitle>
  );
};
