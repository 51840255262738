import { ImageSharpFluid } from '@types';
import { FluidObject } from 'gatsby-image';

export const adaptImage = (
  image:
    | {
        childImageSharp?:
          | {
              fluid?:
                | Pick<
                    ImageSharpFluid,
                    | 'base64'
                    | 'aspectRatio'
                    | 'src'
                    | 'srcSet'
                    | 'srcWebp'
                    | 'srcSetWebp'
                    | 'sizes'
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined,
): FluidObject | undefined => {
  const f = image?.childImageSharp?.fluid || null;
  const result =
    f !== null
      ? {
          aspectRatio: f.aspectRatio !== null ? f.aspectRatio : 1,
          base64: f.base64 ? f.base64 : '',
          sizes: f.sizes ? f.sizes : '',
          src: f.src ? f.src : '',
          srcSet: f.srcSet ? f.srcSet : '',
          srcWebp: f.srcWebp ? f.srcWebp : '',
          srcSetWebp: f.srcSetWebp ? f.srcSetWebp : '',
        }
      : undefined;

  return result;
};
