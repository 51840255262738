import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

export const trackEvent = (
  category: string,
  action: string,
  label: string | undefined = undefined,
  value: number | undefined = undefined,
): void => {
  trackCustomEvent({
    category,
    action,
    label,
    value,
  });
};
