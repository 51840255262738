import React, { ReactNode } from 'react';
import styles from './title.module.scss';

type Props = {
  dark?: boolean;
  hLevel?: number;
  children: ReactNode;
};

export const Title: React.FC<Props> = ({ dark = false, hLevel, children }) => {
  let modifiers = '';
  if (dark) modifiers += styles._dark;

  const TagName = hLevel
    ? (`h${hLevel}` as keyof JSX.IntrinsicElements)
    : 'div';

  return (
    <TagName className={`${styles.container} ${modifiers}`}>{children}</TagName>
  );
};
