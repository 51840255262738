import React from 'react';
import { FluidObject } from 'gatsby-image';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import styles from './splash.module.scss';

type Props = {
  image?: FluidObject;
  full?: boolean;
};

export const Splash: React.FC<Props> = ({ image, full }) => {
  let modifiers = '';
  if (full) modifiers += ` ${styles._full}`;

  return (
    <div className={styles.splash}>
      {image && (
        <div className={`${styles.container} ${modifiers}`}>
          <GatsbyImage
            className={styles.picture}
            fluid={image}
            objectPosition="center top"
            fadeIn={false}
            loading={'eager'}
          />
        </div>
      )}
    </div>
  );
};
