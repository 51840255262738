import React, { ReactNode } from 'react';
import styles from './content.module.scss';

type Props = {
  alt?: boolean;
  first?: boolean;
  children: ReactNode;
};

export const Content: React.FC<Props> = ({
  alt = false,
  first = false,
  children,
}) => {
  let modifiers = '';
  if (alt) modifiers += ` ${styles._alt}`;
  if (first) modifiers += ` ${styles._first}`;

  return (
    <div className={`${styles.container}${modifiers}`}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
