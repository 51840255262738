export function isInViewport(
  element: HTMLDivElement | null,
  offset = 0,
): boolean {
  if (!element) return false;
  const top = element.getBoundingClientRect().top;
  const bottom = element.getBoundingClientRect().bottom;
  return (
    (top >= offset && top < window.innerHeight) ||
    (bottom >= offset && bottom < window.innerHeight)
  );
}
