import React from 'react';
import { Link } from 'gatsby';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import { Content, LayoutDefault } from '@src/ui';
import styles from './not-found.module.scss';

interface Props {
  errorImage: FluidObject | undefined;
}

export const PageNotFound: React.FC<Props> = ({ errorImage }) => {
  return (
    <LayoutDefault>
      <Content first>
        <div className={styles.content}>
          <div className={styles.image}>
            <GatsbyImage fluid={errorImage} fadeIn={false} />
          </div>
          <div className={styles.container}>
            <h1 className={styles.title}>404: Oups !</h1>
            <div className={styles.text}>
              <p>
                Vous venez de tomber sur une page inexistante...
                <br />
                Vous pouvez revenir sur la page d&apos;accueil en cliquant ici :
              </p>
              <Link to="/" className={styles.link}>
                Retour à l&apos;accueil
              </Link>
            </div>
          </div>
        </div>
      </Content>
    </LayoutDefault>
  );
};
