import React from 'react';
import { Link } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import { LayoutDefault, Cities, Paragraph, ButtonLink, Content } from '@src/ui';

type Props = {
  image: FluidObject | undefined;
  city: string;
  prefix?: string;
};

export const PageSecteur: React.FC<Props> = ({ image, city, prefix = 'à' }) => {
  let nearPrefix = 'près de';
  if (prefix === 'en') nearPrefix = 'en';
  if (prefix === 'au') nearPrefix = 'près du';

  return (
    <LayoutDefault
      splashImage={image}
      title={`Rendez-vous vétérinaire ostéopathe chien, chat ${prefix} ${city}`}
      description={`Vétérinaire ostéopathe chiens, chats et NAC ${prefix} ${city}. Ostéopathie animale, homéopathie, phytothérapie : médecines douces et naturelles.`}
    >
      <Content first>
        <Paragraph
          title={`Vétérinaire ostéopathe pour chien, chat et NAC ${prefix} ${city}`}
          hLevel={1}
          centered
        >
          <p>
            Votre vétérinaire ostéopathe <strong>Dr. Gaëlle Morat</strong>{' '}
            exerce {nearPrefix} {city} pour offrir des soins adaptés pour votre
            chien, chat ou NAC en ostéopathie, homéopathie et phytothérapie. Son
            approche combine expertise vétérinaire et médecine complémentaire
            pour soulager les douleurs et améliorer le bien-être de votre animal
            : que ce soit pour prévenir des troubles ou traiter une pathologie,
            le Dr. Gaëlle Morat propose des solutions sur mesure. Prenez
            rendez-vous pour un suivi personnalisé.
          </p>
          <ButtonLink to="/contact/">Contacter le Dr Gaëlle Morat</ButtonLink>
        </Paragraph>
        <br />
        <Paragraph centered>
          Le Dr Gaëlle Morat est disponible {nearPrefix} {city} pour répondre à
          vos questions, que ce soit pour obtenir des conseils ou pour discuter
          de la prise en charge ostéopathique de votre animal, n&apos;hésitez
          pas à contacter le cabinet.
        </Paragraph>
      </Content>

      <Content alt>
        <Paragraph title="Autres médecines complémentaires" hLevel={2} centered>
          En plus de{' '}
          <Link to="/osteopathie-animale/">l&apos;ostéopathie animale</Link>, le
          Dr Gaëlle Morat intervient {nearPrefix} {city} pour vous offrir une
          gamme de soins complémentaires pour le bien-être de votre animal. Ces
          méthodes naturelles incluent la gemmothérapie, la mycothérapie, les
          élixirs floraux, l&apos;homéopathie et la phytothérapie. Ces approches
          douces sont personnalisées selon les besoins spécifiques de chaque
          animal, dans une démarche globale et respectueuse.
        </Paragraph>
      </Content>

      <Cities title={`Disponible ${prefix} ${city} et ses environs`} />
    </LayoutDefault>
  );
};
