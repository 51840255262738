import React from 'react';
import { FluidObject } from 'gatsby-image';
import {
  config,
  Content,
  FormContact,
  Knowledge,
  LayoutDefault,
  Paragraph,
} from '@src/ui';
import styles from './home.module.scss';

interface IndexProps {
  image: FluidObject | undefined;
  presentationImage: FluidObject | undefined;
  approcheImage: FluidObject | undefined;
  knowledgesData: {
    id: string;
    title: string;
    link?: string;
    image?: FluidObject;
  }[];
}

export const PageHome: React.FC<IndexProps> = ({
  image,
  presentationImage,
  approcheImage,
  knowledgesData,
}) => {
  const heading = (
    <div className={styles.heading}>
      <div className={styles.titleBlock}>
        <h1>
          <span className={styles.title}>{config.title}</span>
          <span className={styles.subtitle}>{config.subtitle}</span>
        </h1>
      </div>
      <div className={styles.mainKnowledges}>
        <h2 className={styles.mainKnowledge}>Ostéopathie</h2>
        <h2 className={styles.mainKnowledge}>Traitements naturels</h2>
        <h2 className={styles.mainKnowledge}>Homéopathie</h2>
      </div>
    </div>
  );

  return (
    <LayoutDefault splashImage={image} splashContent={heading} hideTitle={true}>
      <Content>
        <Paragraph
          title="Formation et parcours vétérinaire"
          hLevel={2}
          centered
          right
          cta={{
            text: 'Découvrir mon parcours',
            url: '/presentation-et-parcours/',
          }}
          image={presentationImage}
        >
          <p>
            Vétérinaire diplômée de l&apos;école nationale vétérinaire de Lyon,
            j&apos;exerce en clientèle canine depuis 2013.
          </p>
          <p>
            Passionnée par les médecines douces et naturelles, je propose des
            consultations d&apos;ostéopathie et de médecine complémentaire pour
            chiens, chats et NAC. Je suis localisée aux Marches, à 10 minutes de
            Chambéry et 30 minutes de Grenoble, au coeur de la vallée du
            Grésivaudan.
          </p>
        </Paragraph>
      </Content>

      <Content alt>
        <Paragraph title="Thérapies chiens, chats et NAC" hLevel={2} centered>
          Selon le besoin de votre animal et votre demande, j&apos;utiliserai
          une ou plusieurs thérapeutiques à ma disposition. Elles peuvent être
          utilisées seules ou venir renforcer le traitement médical déjà mis en
          place par votre vétérinaire traitant.
        </Paragraph>
        <section className={styles.sfList}>
          {knowledgesData.map(k => (
            <div key={k.id} className={styles.sfItem}>
              <Knowledge {...k} />
            </div>
          ))}
        </section>
      </Content>

      <Content>
        <Paragraph
          title="Une approche douce pour vos chiens, chats et NAC"
          hLevel={2}
          centered
          cta={{
            text: 'Découvrir ma pratique en détail',
            url: '/mon-approche/',
          }}
          image={approcheImage}
        >
          <p>
            Au fil des années de pratique, j&apos;ai intégré les médecines
            complémentaires à ma formation vétérinaire afin de proposer une
            autre façon de prendre en charge les pathologies de mes patients.
          </p>
          <p>
            J&apos;ai choisi d&apos;associer la médecine traditionnelle et les
            traitements naturels pour proposer une approche plus douce et plus
            complète à vos animaux de compagnie.
          </p>
        </Paragraph>
      </Content>

      <FormContact />
    </LayoutDefault>
  );
};
