import React from 'react';
import styles from './burger.module.scss';

type Props = {
  active: boolean;
  clickHandler: () => void;
};

export const Burger: React.FC<Props> = ({ active, clickHandler }) => (
  <button
    aria-label={active ? 'Fermer le menu' : 'Ouvrir le menu'}
    onClick={clickHandler}
    className={`${styles.burger} ${active ? styles.active : ''}`}
  >
    <div className={styles.inner}></div>
  </button>
);
