import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FluidObject } from 'gatsby-image';
import { Header, Footer, Splash, SEOHelmet, useDebounce } from '@src/ui';
import { isInViewport } from '@src/services/viewport';
import styles from './layout-default.module.scss';

const DEBOUNCE_TIMER = 300;

type Props = {
  children: React.ReactNode;
  title?: string;
  hideTitle?: boolean;
  description?: string;
  splashContent?: React.ReactNode;
  splashImage?: FluidObject;
};

export const LayoutDefault: React.FC<Props> = ({
  children,
  title,
  hideTitle = false,
  description,
  splashContent,
  splashImage,
}: Props) => {
  const [isTitleHidden, setIsTitleHidden] = useState(hideTitle);
  const splashRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (splashRef.current) {
      setIsTitleHidden(isInViewport(splashRef.current, 100));
    }
  }, []);

  const debouncedHandleScroll = useDebounce(handleScroll, DEBOUNCE_TIMER);

  useEffect(() => {
    if (!hideTitle) return;

    window.addEventListener('scroll', debouncedHandleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [hideTitle, debouncedHandleScroll, handleScroll]);

  return (
    <div>
      <Header titleHidden={isTitleHidden} />

      <main className={styles.main} role="main">
        <div ref={splashRef} className={styles.splash}>
          <Splash image={splashImage} full={!!splashContent} />
          {splashContent && (
            <div className={styles.splashContent}>{splashContent}</div>
          )}
        </div>
        <div className={styles.page}>{children}</div>
      </main>

      <Footer />

      <SEOHelmet title={title} description={description} />
    </div>
  );
};
