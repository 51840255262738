import React from 'react';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import {
  Cities,
  config,
  Content,
  FormContact,
  LayoutDefault,
  Paragraph,
  Title,
} from '@src/ui';
import { formatPhone, i18nPhone } from '@src/services/strings';
import styles from './contact.module.scss';

interface Props {
  image: FluidObject | undefined;
  contactImage: FluidObject | undefined;
}

export const PageContact: React.FC<Props> = ({ image, contactImage }) => {
  return (
    <LayoutDefault
      splashImage={image}
      title="Rendez-vous vétérinaire ostéopathe - Isère et Savoie"
      description="Rendez-vous ostéopathie, homéopathie, phytothérapie pour chien, chat, NAC, Vétérinaire Ostéopathe en Isère et Savoie, entre Chambéry et Grenoble."
    >
      <Content first>
        <div className={styles.contact}>
          <div className={styles.section}>
            <Title hLevel={1}>Prendre rendez-vous</Title>
            <div className={styles.coordinates}>
              <h2 className={styles.coordinatesTitle}>Par téléphone</h2>
              <OutboundLink
                className={styles.cta}
                href={`tel:${i18nPhone(config.phone)}`}
                eventCategory="CTA"
                eventAction="Click"
                eventLabel="Phone"
              >
                {formatPhone(config.phone)}
              </OutboundLink>
            </div>
            <div className={styles.coordinates}>
              <h2 className={styles.coordinatesTitle}>Par email</h2>
              <OutboundLink
                className={styles.cta}
                href={`mailto:${config.email}`}
                eventCategory="CTA"
                eventAction="Click"
                eventLabel="Email"
              >
                {config.email}
              </OutboundLink>
            </div>
          </div>
          <div className={styles.sectionImage}>
            <GatsbyImage
              className={styles.image}
              fluid={contactImage}
              alt={``}
            />
          </div>
          <div className={styles.section}>
            <Title hLevel={2}>Où me trouver</Title>
            <div>Retrouvez-moi aux Marches, à 10 minutes de Chambéry :</div>
            <address className={styles.address}>{config.address}</address>
            <div className={styles.goto}>
              <OutboundLink
                className={styles.cta}
                target="_blank"
                href={`https://www.google.com/maps/dir/?api=1&destination=${config.address}`}
                eventCategory="CTA"
                eventAction="Click"
                eventLabel="Address"
              >
                Voir la carte
              </OutboundLink>
            </div>
          </div>
        </div>
      </Content>

      <Content alt>
        <Paragraph
          title="Consultations ostéopathiques chien, chat et NAC uniquement"
          hLevel={2}
          centered
        >
          J&apos;exerce exclusivement en ostéopathie et phytothérapie et ne
          réalise donc aucun acte de médecine vétérinaire courante. En cas
          d&apos;urgence ou de nécessité de soins autre que l&apos;ostéopathie
          merci de contacter votre vétérinaire traitant ou, si votre animal ne
          possède pas de vétérinaire traitant, de joindre le CHV Saint-Martin au
          04.50.600.900.
        </Paragraph>
      </Content>

      <Cities title="Villes à proximité" />

      <FormContact />
    </LayoutDefault>
  );
};
