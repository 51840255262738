import React from 'react';
import { Link } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import {
  Paragraph,
  Subtitle,
  SmallParagraph,
  LayoutDefault,
  Content,
  FormContact,
} from '@src/ui';
import styles from './presentation.module.scss';

interface Props {
  image: FluidObject | undefined;
  tetrasImage: FluidObject | undefined;
  siriusImage: FluidObject | undefined;
}

export const PagePresentation: React.FC<Props> = ({
  image,
  tetrasImage,
  siriusImage,
}) => {
  return (
    <LayoutDefault
      splashImage={image}
      title="Parcours vétérinaire ostéopathe chiens et chats - Isère et Savoie"
      description="Parcours et formation de vétérinaire ostéopathe. Médecines complémentaires : ostéopathie animale, phytothérapie, homéopathie, entre Chambéry et Grenoble."
    >
      <Content first>
        <Paragraph title="Formation de vétérinaire ostéopathe" hLevel={1}>
          <p>
            J&apos;exerce en tant que vétérinaire canin généraliste depuis 11
            ans et utilise{' '}
            <Link to="/osteopathie-animale/">l&apos;ostéopathie</Link> et la
            phytothérapie dans <Link to="/mon-approche/">ma pratique</Link>{' '}
            depuis plus de 9 ans. Ayant grandi dans une famille faisant
            régulièrement appel aux médecines complémentaires, je me suis
            naturellement intéressée à leur utilisation en médecine vétérinaire
            dès mes études à l&apos;Ecole Nationale Vétérinaire de Lyon.
          </p>
          <p>
            J&apos;ai ensuite suivi différentes formations afin d&apos;étendre
            mes connaissances dans le domaine.
          </p>
          <br />
          <Subtitle hLevel={2}>Cursus vétérinaire</Subtitle>
          <p>
            <ul>
              <li>
                <h3 className="hinline">Thèse de doctorat vétérinaire</h3>
                <span>
                  {' '}
                  en 2013 sur{' '}
                  <a
                    href="https://www2.vetagro-sup.fr/bib/fondoc/th_sout/dl.php?file=2013lyon040.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    l&apos;utilisation de l&apos;homéopathie pour les animaux
                  </a>
                </span>
              </li>
              <li>
                <h3 className="hinline">Formation en phytothérapie</h3>{' '}
                <span>à VetagroSup en 2013</span>
              </li>
            </ul>
          </p>
          <br />
          <Subtitle hLevel={2}>Autres formations et perfectionnements</Subtitle>
          <p>
            <ul>
              <li>
                <h3 className="hinline">
                  Formation en ostéopathie vétérinaire
                </h3>
                <span>
                  {' '}
                  de 2014 à 2017 au sein de l&apos;école vétérinaire de
                  médecines intégratives{' '}
                  <a
                    href="https://avetao.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    AVETAO
                  </a>
                </span>
              </li>
              <li>
                <h3 className="hinline">Perfectionnement en gémmothérapie</h3>
                <span> en 2020 auprès d&apos;une naturopathe diplômée</span>
              </li>
              <li>
                <h3 className="hinline">Formation en florithérapie</h3>
                <span>
                  {' '}
                  auprès de la{' '}
                  <a
                    href="https://federation-edelweiss.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    fédération Edelweiss
                  </a>{' '}
                  de 2020 à 2021 (utilisation des élixirs floraux du Dr Bach et
                  élixirs floraux contemporains DEVA)
                </span>
              </li>
              <li>
                <h3 className="hinline">Formation en homéopathie</h3>
                <span>
                  {' '}
                  de 2020 à 2023 à{' '}
                  <a
                    href="https://ecole-homeopathie-auvergne-rhone-alpes.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    l&apos;EHARA
                  </a>{' '}
                  (École d&apos;Homéopathie Auvergnes-Rhône-Alpes)
                </span>
              </li>
              <li>
                <h3 className="hinline">Formation au LMO</h3>
                <span>
                  {' '}
                  en 2023-2024 (
                  <a
                    href="https://lmosteo.com/le-lmo-animalier"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lien Mécanique Ostéopathique
                  </a>
                  )
                </span>
              </li>
              <li>
                <h3 className="hinline">
                  Diplôme Universitaire Plantes médicinales, phytothérapie et
                  aromathérapie
                </h3>
                <span> en cours à la faculté de Pharmacie de Lyon</span>
              </li>
            </ul>
          </p>
        </Paragraph>
      </Content>

      <Content alt>
        <h2 className={styles.title}>Interventions et évènements sportifs</h2>
        <div className={styles.contentList}>
          <SmallParagraph
            title={'Le Tetras Libre'}
            hLevel={3}
            image={tetrasImage}
          >
            J&apos;interviens régulièrement au Centre de Sauvegarde de la faune
            sauvage{' '}
            <strong>
              <a
                href="https://www.csfs-paysdesavoie.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Le Tetras Libre
              </a>
            </strong>{' '}
            en tant que bénévole afin de pratiquer des soins sur les animaux
            blessés. Chaque animal, qu&apos;il soit domestique ou sauvage,
            mérite un soutien pour accélérer sa récupération après un accident.
            Vous pouvez en découvrir plus sur mes interventions en regardant les
            vidéos disponibles sur la page détaillant{' '}
            <Link to="/mon-approche/#tetras">mon approche</Link>.
          </SmallParagraph>
          <SmallParagraph
            title={'Trophée de Sirius'}
            hLevel={3}
            image={siriusImage}
          >
            Je suis intervenue en tant que bénévole au{' '}
            <strong>
              <a
                href="https://www.facebook.com/people/Troph%25C3%25A9e-De-Sirius/pfbid02PjbKeK1JAiKCwnsQTN2TxMTeeG89csWet3FxRDYwhPtBDXzNRkPLL4jZoKKYM7sSl/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Trophée de Sirius 2024
              </a>
            </strong>{' '}
            des Aiguilles d&apos;Arves pour proposer des consultations
            d&apos;ostéopathie aux chiens après les courses et réaliser des
            ateliers de sensibilisation sur les bonnes pratiques pour une
            activité physique adaptée avec son chien.
          </SmallParagraph>
        </div>
      </Content>

      <FormContact />
    </LayoutDefault>
  );
};
