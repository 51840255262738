import React from 'react';
import { Link } from 'gatsby';
import styles from './city.module.scss';

type Props = {
  name: string;
  slug: string;
};

export const City: React.FC<Props> = ({ name, slug }: Props) => {
  return (
    <h4>
      <Link
        className={styles.link}
        to={`/secteur/prendre-rendez-vous-veterinaire-osteopathe-${slug}/`}
      >
        {name}
      </Link>
    </h4>
  );
};
