import React, { ReactNode } from 'react';
import { Link } from 'gatsby';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import { config } from '@src/ui';
import { trackEvent } from '@src/services/analytics';
import styles from './small-paragraph.module.scss';

type Props = {
  title?: string;
  hLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  image?: FluidObject;
  right?: boolean;
  cta?: {
    url: string;
    text: string;
  };
  centered?: boolean;
  dark?: boolean;
  children: ReactNode;
};

export const SmallParagraph: React.FC<Props> = ({
  title,
  hLevel = 1,
  image,
  right = false,
  cta,
  centered = false,
  dark = false,
  children,
}) => {
  const HTag = `h${hLevel}` as keyof JSX.IntrinsicElements;
  const picturePrefix = `${config.title} - ${config.subtitle} - `;

  return (
    <section className={`${styles.paragraph} ${dark ? styles.dark : ''}`}>
      <div>
        <div className={styles.container}>
          {image && (
            <div
              className={`${styles.imageContainer} ${
                right ? styles.right : ''
              }`}
            >
              <GatsbyImage
                className={styles.image}
                fluid={image}
                alt={picturePrefix + title}
              />
            </div>
          )}
          <div
            className={`${styles.textContainer} ${image ? '' : styles.full} ${
              centered === true ? styles.centered : ''
            }`}
          >
            <HTag className={styles.title}>{title}</HTag>
            {children}
            {cta && (
              <Link
                className={styles.link}
                to={cta.url}
                onClick={() => trackEvent('Paragraph', 'Click', cta?.url)}
              >
                {cta.text}
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
