export const config = {
  siteUrl: `https://www.morat-osteo.vet`,
  title: 'Dr. Gaëlle Morat',
  subtitle: 'Vétérinaire Ostéopathe - Isère et Savoie',
  defaultDescription:
    'Vétérinaire ostéopathe chiens, chats et NAC, entre Chambéry et Grenoble. Médecines complémentaires : ostéopathie animale, phytothérapie, homéopathie.',
  author: '@KAvrillon',
  phone: '0749371702',
  email: 'contact@morat-osteo.vet',
  address: '100 Chemin des Lauriers, 73800 Porte-de-Savoie',
};
