/* eslint-disable react/jsx-no-target-blank */

import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { config } from '@src/ui';
import { trackEvent } from '@src/services/analytics';
import styles from './footer.module.scss';

export const Footer: React.FC = () => {
  return (
    <footer className={styles.footer} role="contentinfo">
      <div className={styles.content}>
        <div className={styles.baseline}>
          <span className={styles.title}>{config.title}</span>
          <span className={styles.subtitle}>{config.subtitle}</span>
        </div>

        <div className={styles.facebook}>
          <a
            href={
              'https://www.facebook.com/people/Dr-Morat-V%C3%A9t%C3%A9rinaire-Ost%C3%A9opathie-Phytoth%C3%A9rapie-Hom%C3%A9opathie/61566325957215/'
            }
            target="_blank"
            className={styles.link}
            onClick={() => {
              trackEvent('Footer', 'Facebook');
            }}
          >
            Facebook
          </a>
        </div>
        <div className={styles.cgf}>
          <Link
            to={'/cgf/'}
            className={styles.link}
            onClick={() => {
              trackEvent('Footer', 'CGF');
            }}
          >
            CGF
          </Link>
        </div>
        <div className={styles.copyright}>
          <OutboundLink
            href="https://avrillon.me"
            target="_blank"
            rel="noopener"
            className={styles.link}
            eventCategory="Footer"
            eventAction="Click"
            eventLabel="Copyright"
          >
            @ {new Date().getFullYear()} avrillon.me
          </OutboundLink>
        </div>
      </div>
    </footer>
  );
};
