import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { i18nPhone } from '@src/services/strings';
import ogImage from '@src/images/og.jpg';

type Props = {
  title?: string;
  description?: string;
};

const SITE_URL = 'https://www.morat-osteo.vet';
const AUTHOR = '@KAvrillon';
const PHONE = '0749371702';
const EMAIL = 'contact@morat-osteo.vet';
const DEFAULT_TITLE =
  'Dr Gaëlle Morat - Vétérinaire Ostéopathe - Isère et Savoie';
const DEFAULT_DESCRIPTION =
  'Vétérinaire ostéopathe chiens, chats et NAC, entre Chambéry et Grenoble. Médecines complémentaires : ostéopathie animale, phytothérapie, homéopathie.';

export const SEOHelmet: React.FC<Props> = (props: Props) => {
  const { title, description = DEFAULT_DESCRIPTION } = props;
  const { pathname } = useLocation();

  const fullTitle = title ? `${title} | ${DEFAULT_TITLE}` : DEFAULT_TITLE;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'fr',
      }}
      title={fullTitle}
      link={[
        {
          rel: 'canonical',
          href: `${SITE_URL}${pathname}`,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: fullTitle,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `${SITE_URL}${ogImage}`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:url`,
          content: SITE_URL,
        },
        {
          name: `twitter:image`,
          content: ogImage,
        },
        {
          name: `twitter:creator`,
          content: AUTHOR,
        },
        {
          name: `twitter:title`,
          content: fullTitle,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ]}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "https://schema.org",
            "@type": "WebSite",
            "@id": "${SITE_URL}#website",
            "headline": "${DEFAULT_TITLE}",
            "name": "${DEFAULT_TITLE}",
            "description": "${DEFAULT_DESCRIPTION}",
            "url": "${SITE_URL}"
          }`,
        },
        {
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "https://schema.org",
            "@type": "Person",
            "@id": "${SITE_URL}#Person",
            "name": "Dr Gaëlle Morat",
            "url": "${SITE_URL}",
            "jobTitle": "Vétérinaire Ostéopathe",
            "telephone": "${i18nPhone(PHONE || '')}",
            "email": "${EMAIL}",
            "sameAs": ["https://www.linkedin.com/in/ga%C3%ABlle-morat-4079a0170/", "https://www.facebook.com/people/Dr-Morat-V%C3%A9t%C3%A9rinaire-Ost%C3%A9opathie-Phytoth%C3%A9rapie-Hom%C3%A9opathie/61566325957215/"]
          }`,
        },
        {
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "@id": "${SITE_URL}#breadcrumb",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "item": {
                  "@id": "${SITE_URL}",
                  "name": "${DEFAULT_TITLE}"
                }
              }
              ${
                pathname !== '/'
                  ? `,{
                "@type": "ListItem",
                "position": 2,
                "item": {
                  "@id": "${SITE_URL}${pathname}",
                  "name": "${title}"
                }
              }`
                  : ''
              }
            ]
          }`,
        },
      ]}
    />
  );
};
