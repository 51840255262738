import React, { useState } from 'react';
import { Link } from 'gatsby';
import { config, Burger } from '@src/ui';
import { trackEvent } from '@src/services/analytics';
import { HeaderLink } from './header-link';
import styles from './header.module.scss';

type Props = {
  titleHidden?: boolean;
};

export const Header: React.FC<Props> = ({ titleHidden = false }) => {
  const [open, setOpen] = useState(false);

  return (
    <header
      className={`${styles.hf} ${open ? styles.open : ''}  ${
        titleHidden ? '' : styles.titleVisible
      }`}
    >
      <div className={styles.heading}>
        <div className={styles.headingTitle}>
          <span className={styles.headingFirst}>{config.title}</span>
          <span className={styles.headingSecond}>{config.subtitle}</span>
        </div>
      </div>
      <nav className={styles.links} role="navigation">
        <div className={styles.linksButton}>
          <Burger
            clickHandler={() => {
              trackEvent('Header', open ? 'Close' : 'Open');
              setOpen(!open);
            }}
            active={open}
          />
        </div>
        <ul className={styles.linksList}>
          <HeaderLink to="/" label="Accueil" open={open} />
          <HeaderLink
            to="/presentation-et-parcours/"
            label="Présentation"
            open={open}
          />
          <HeaderLink to="/mon-approche/" label="Approche" open={open} />
          <HeaderLink
            to="/osteopathie-animale/"
            label="Ostéopathie"
            open={open}
          />
          <HeaderLink
            to="/contact/"
            label="Prendre RDV"
            open={open}
            highlight
          />
        </ul>
        <Link
          to="/contact/"
          className={styles.mobileContact}
          onClick={() => {
            trackEvent('Header', 'Contact');
          }}
        >
          Prendre RDV
        </Link>
      </nav>
    </header>
  );
};
