import React from 'react';
import { Link } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import {
  Content,
  FormContact,
  LayoutDefault,
  Paragraph,
  Subtitle,
} from '@src/ui';
import styles from './approche.module.scss';

interface Props {
  image: FluidObject | undefined;
}

export const PageApproche: React.FC<Props> = props => (
  <LayoutDefault
    splashImage={props.image}
    title="Approche ostéopathie animale - Isère et Savoie"
    description="Une approche douce pour vos chiens et chats. Médecines naturelles : ostéopathie animale, phytothérapie, homéopathie, entre Chambéry et Grenoble."
  >
    <Content first>
      <Paragraph title="Ma vision du métier de vétérinaire" hLevel={1}>
        <p>
          Je respecte profondément mes patients. Je travaille pour eux, avec
          eux, et en fonction d&apos;eux.
        </p>
        <p>
          Mon objectif est de veiller au{' '}
          <strong>bien-être de votre animal</strong> en tenant compte de qui il
          est. Certains de nos compagnons sont très dociles alors que
          d&apos;autres le sont un peu moins. Certains sont fatigués,
          douloureux, stressés ou apeurés et c&apos;est à moi en tant que
          vétérinaire de m&apos;adapter à eux, non l&apos;inverse.
        </p>
        <p>
          L&apos;ostéopathie me permet de créer un lien différent avec
          l&apos;animal lors des soins. Je ne lui impose pas un traitement,{' '}
          <strong>je lui montre que ma manipulation le soulage</strong>. Ils
          perçoivent souvent dès la première consultation que la relation est
          différente et alors qu&apos;ils étaient anxieux et méfiants à leur
          arrivée, ils se détendent progressivement sur la table et me laissent
          faire en confiance. Les propriétaires s&apos;étonnent souvent de voir
          leur animal monter sereinement sur la table lors de la deuxième
          séance.
        </p>
        <p>
          La qualité d&apos;un soin est un travail d&apos;équipe entre
          l&apos;animal, le vétérinaire et vous. Vous êtes celui qui connaît le
          mieux votre animal, et c&apos;est vous qui savez le mieux quand ça ne
          va pas. Un traitement, quel qu&apos;il soit, ne peut fonctionner
          correctement si l&apos;un des membres de l&apos;équipe est négligé.
          C&apos;est pourquoi lors de la consultation j&apos;ai à cœur de{' '}
          <strong>vous détailler mon diagnostic</strong>, et de discuter avec
          vous de l&apos;approche que je vais mettre en place pendant la
          consultation.
        </p>
        <p>
          Je travaille en lien avec votre vétérinaire traitant pour privilégier
          une approche équilibrée entre les méthodes complémentaires et les
          traitements conventionnels.{' '}
          <Link to="/presentation-et-parcours/">Ma formation</Link> me permet
          une prise en charge plus complète que celle d&apos;un ostéopathe
          animalier, en{' '}
          <strong>
            alliant mon savoir faire vétérinaire et les médecines
            complémentaires
          </strong>
          , selon les besoins spécifiques de chaque cas. L&apos;objectif est de
          toujours privilégier le traitement le plus efficace et le plus doux
          pour le bien-être de votre animal.
        </p>
      </Paragraph>
    </Content>

    <div id="tetras">
      <Content alt>
        <Paragraph title="L'ostéopathie animale en images" hLevel={2}>
          <Subtitle hLevel={3}>
            Interventions au centre Le Tetras Libre
          </Subtitle>
          <p>
            Pour aller plus loin dans la compréhension de ma pratique, je vous
            invite à regarder une présentation de mes interventions au Centre de
            Sauvegarde de la faune sauvage <strong>Le Tetras Libre</strong> :
          </p>
        </Paragraph>

        <div className={styles.videos}>
          <iframe
            title="Ostéopathie en faune sauvage"
            src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FCentreSauvegardeFauneSauvagedesSavoie%2Fvideos%2F1089807502142055%2F&show_text=true&width=560&t=0"
            height="540"
            style={{ border: 'none', overflow: 'hidden' }}
            scrolling="no"
            frameBorder="0"
            allowFullScreen={true}
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
      </Content>
    </div>

    <FormContact />
  </LayoutDefault>
);
