import React from 'react';
import { Link } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import { Content, LayoutDefault, Paragraph } from '@src/ui';

interface Props {
  image: FluidObject | undefined;
}

export const PageCGF: React.FC<Props> = ({ image }) => {
  return (
    <LayoutDefault splashImage={image} title="CGF">
      <Content first>
        <Paragraph
          title={
            "Conditions Générales de Fonctionnement de l'EIRL Gaëlle Morat"
          }
        >
          <p>
            Les présentes conditions générales de fonctionnements sont
            consultables dans notre salle d&apos;attente, elles peuvent vous
            être remises sous un format papier à la clinique. Tout acte effectué
            dans notre établissement de soins vétérinaires est soumis aux
            présentes conditions générales de fonctionnement.
          </p>
          <h2>Appellation de l&apos;établissement de soins</h2>
          <p>
            L&apos;EIRL Gaëlle MORAT répond au cahier des charges des cabinet
            vétérinaire avec exercice exclusif en ostéopathie et phytothérapie
            et aux obligations telles que définies par l&apos;Arrêté du 13 mars
            2015 relatif aux catégories d&apos;établissements de soins
            vétérinaires et par le cahier des charges établi par le conseil
            national de l&apos;Ordre des vétérinaires et disponibles et
            disponibles sur le site de l&apos;Ordre des Vétérinaires.
          </p>
          <ul>
            <li>
              <strong>Adresse :</strong> 100 Chemin des Lauriers, 73800 Les
              Marches
            </li>
            <li>
              <strong>N° de téléphone :</strong> 07.49.37.17.02
            </li>
            <li>
              <strong>Adresse email :</strong> contact@morat-osteo.vet
            </li>
          </ul>
          <p>
            Les espèces soignées sont les animaux de compagnie (chiens, chats).
          </p>
          <h2>
            Horaires d&apos;ouverture habituels et conditions d&apos;accueil du
            public
          </h2>
          <p>
            L&apos;EIRL Gaëlle MORAT est ouverte le lundi, mardi, jeudi et jeudi
            de 09h00 à 18h30. Les consultations ont lieu sur rendez-vous
            uniquement. Aux heures de fermeture de l&apos;EIRL Gaëlle MORAT, une
            convention a été passée avec le service d&apos;urgence du CHV Saint
            Martin, joignable au 04.50.600.900.
          </p>
          <p>
            Les horaires d&apos;ouverture sont indicatifs et ne sauraient être
            opposés au vétérinaire en cas de fermeture, notamment les jours
            fériés.
          </p>
          <p>
            Les consultations ont lieu sur rendez-vous. En dehors de ces
            horaires, le dimanche et les jours fériés, les vétérinaires du CHVSM
            assurent les urgences. Pour les contacter, veuillez appeler le :
            04.50.600.900.
          </p>
          <p>
            Hormis les cas prévus par le code de déontologie, l&apos;EIRL Gaëlle
            MORAT se réserve la possibilité de refuser tout client qui :
          </p>
          <ul>
            <li>
              se rend coupable d&apos;incivilité, insulte, injurie, menace,
              crie, diffame à l&apos;écrit ou à l&apos;oral
            </li>

            <li>
              manifeste tout comportement violent, inapproprié ou inadapté
            </li>

            <li>est en défaut de paiement</li>

            <li>
              ne vient pas à 2 rendez-vous sans avoir pris le soin
              d&apos;annuler
            </li>

            <li>formule une demande heurtant la conscience du vétérinaire</li>

            <li>
              ne tient pas son animal dans des conditions permettant
              d&apos;assurer la sécurité et l&apos;intégrité physique du
              personnel soignant ou refuse les moyens de contention proposés
              et/ou les consignes données»
            </li>
          </ul>
          <h2>Vétérinaire responsable de l&apos;établissement</h2>
          <p>DV Gaëlle MORAT</p>
          <h2>Personnel affecté aux soins des patients</h2>
          <p>Personnel vétérinaire :</p>
          <p>DV Gaëlle MORAT</p>
          <h2>Prestations effectuées au sein de l&apos;EIRL Gaëlle Morat</h2>
          <ul>
            <li>
              Consultation d&apos;ostéopathie, homéopathie et phytothérapie
            </li>
            <li>Délivrance de compléments alimentaires et phytothérapie</li>
          </ul>
          <p>
            Pour les actes nécessitant du matériel non disponible dans la
            structure ou une compétence spécifique, l&apos;EIRL Gaëlle MORAT
            réoriente les animaux vers leur vétérinaire traitant ou vers le
            CHVSM s&apos;ils ne possèdent pas de vétérinaire traitant.
          </p>
          <h2>Absence d&apos;hospitalisation sur place</h2>
          <p>
            En raison de l&apos;activité exclusive en ostéopathie et
            phytothérapie l&apos;EIRL Gaëlle MORAT n&apos;effectue pas
            d&apos;hospitalisation des animaux.
          </p>
          <h2>Permanence et continuité des soins</h2>
          <p>
            En raison de l&apos;activité exclusive en ostéopathie et
            phytothérapie l&apos;EIRL Gaëlle MORAT n&apos;effectue pas de soins
            de médecine vétérinaire courante ni de consultation d&apos;urgence.
            Nous sommes joignables au téléphone pour toute question relative à
            la consultation d&apos;ostéopathie ou phytothérapie. Pour toute
            prise en charge médicale les animaux sont réorientés vers leur
            vétérinaire traitant. S&apos;ils n&apos;en possèdent pas, notre
            établissement a passé un contrat de gestion des urgences avec la
            structure suivante :
          </p>
          <p>
            Centre hospitalier CHVSM situé à Allonzier-la-caille et joignable au
            04.50.600.900.
          </p>
          <h2>Espèces traitées</h2>
          <p>Les animaux de compagnie chiens et chats.</p>
          <p>
            En cas de demande de la part du propriétaire une séance
            d&apos;ostéopathie peut être réalisée également sur lapin ou cobaye.
          </p>
          <h2>Modalités d&apos;admission des animaux</h2>
          <p>
            Les chats doivent être dans un panier de transport fermé et adapté à
            leur morphologie et leur physiologie. Les chiens doivent être tenus
            en laisse et muselés si besoin.
          </p>
          <h2>
            Modalités d&apos;admission des animaux visés par la legislation sur
            les chiens dangereux
          </h2>
          <p>
            Les chiens de première et deuxième catégories sont acceptés dans
            notre établissement à la condition expresse qu&apos;ils soient
            muselés et présentés par une personne majeure.
          </p>
          <h2>Ventes de médicaments et de produits de parapharmacie</h2>
          <p>
            En raison de son exercice exclusif en ostéopathie et phytothérapie
            l&apos;EIRL Gaëlle MORAT ne vend pas de médicaments soumis à
            prescription. Si l&apos;état de l&apos;animal nécessite un
            traitement médical il sera réorienté vers son vétérinaire traitant.
            Par dérogation, l&apos;EIRL Gaëlle est autorisé à vendre des
            produits dérogatoires, de parapharmacie vétérinaire. Les produits
            vendus (hors médicaments sur prescription) peuvent être repris si
            non déballés, non ouverts, dans les 5 jours qui suivent la vente sur
            présentation d&apos;une facture émise par l&apos;EIRL Gaëlle MORAT.
          </p>
          <p>
            Conditions tarifaires, chiffrages et devis Les tarifs des principaux
            actes sont disponibles au cabinet. En dehors des actes codifiés, le
            vétérinaire peut vous établir un chiffrage pour tout acte.
          </p>
          <h2>Modalités de règlement</h2>
          <p>
            Les honoraires sont payables comptants en fin de consultation. De
            manière exceptionnelle, un paiement différé peut être accepté selon
            des modalités convenues entre le vétérinaire et le client. Un
            acompte pourra être demandé.
          </p>
          <p>
            Le paiement par carte n&apos;est pas accepté, il peut se faire par
            chèque ou en espèces.
          </p>
          <p>
            Les honoraires sont dus dès la prise en charge de l&apos;animal. Le
            code de déontologie interdit aux vétérinaires de facturer un acte en
            fonction du résultat. Les honoraires liés aux actes effectués sont
            donc dus en intégralité. En cas de non-paiement des honoraires,
            l&apos;EIRL Gaëlle MORAT appliquera l&apos;indemnité forfaitaire
            pour frais de recouvrement de 40 € prévue à l&apos;article L441-6 du
            code du commerce ; sans préjuger d&apos;une procédure en injonction
            de payer auprès du tribunal d&apos;instance de Chambéry.
          </p>
          <h2>Communication</h2>
          <p>
            L&apos;EIRL Gaëlle MORAT dispose d&apos;un site internet
            d&apos;information à l&apos;adresse suivante :{' '}
            <a href="https://www.morat-osteo.vet">www.morat-osteo.vet</a>.
          </p>
          <h2>Accessibilité</h2>
          <p>
            Conformément au Décret n° 2017-431 du 28 mars 2017, le Registre
            Public d&apos;Accessibilité de l&apos;EIRL Gaëlle MORAT est
            consultable au cabinet.
          </p>
          <h2>Déontologie</h2>
          <p>
            En cas de différend relevant de la déontologie, le Conseil Régional
            de l&apos;Ordre des Vétérinaires Auvergne Rhône-Alpes peut être
            sollicité :
          </p>
          <h2>CROV-AURA</h2>
          <p>
            110 avenue Barthélémy Buyer
            <br />
            69009 LYON
            <br />
            Tél : 04 72 57 16 65
            <br />
            E-mail : cro.aura@ordre.veterinaire.fr
            <br />
          </p>
          <h2>Litiges</h2>
          <p>
            Tout litige doit donner lieu à un courrier écrit envoyé en
            Recommandé avec Accusé de Réception à l&apos;EIRL Gaëlle MORAT, 100
            chemin des Lauriers, 73800 Porte-de-Savoie. En cas de contestation
            des solutions proposées, le médiateur des litiges de la consommation
            de la profession de vétérinaire a pour objectif de régler de manière
            amiable les différends financiers survenant entre tout détenteur
            d&apos;un animal, client d&apos;un vétérinaire non professionnel et
            tout vétérinaire inscrit au tableau de l&apos;ordre. Il intervient
            sur les litiges relatifs aux prestations des vétérinaires (notamment
            ceux relatifs aux honoraires, à des prestations ou ventes
            accessoires). Conformément à l&apos;article L 152-1 du Code de la
            consommation, en cas de litiges de la consommation vous pouvez
            contacter le médiateur à l&apos;adresse internet suivante :
            mediateur-conso@veterinaire.fr ou par voie postale :
          </p>
          <p>
            Médiateur de la consommation de la profession de vétérinaire,
            Conseil National de l&apos;Ordre des Vétérinaires, 34 rue Bréguet,
            75011 PARIS.
          </p>
          <h2>Assurance responsabilité civile professionnelle</h2>
          <p>
            Abeille IARD & Santé, 13 rue du moulin Bailly, 92271 Bois Colombes
            CEDEX.
          </p>
          <h2>Politique de confidentialité</h2>
          <p>
            L&apos;EIRL Gaëlle MORAT dispose d&apos;un système informatique
            destiné à faciliter la gestion des dossiers des patients et à
            assurer la facturation des actes, médicaments vétérinaires et autres
            produits. Dans le cadre d&apos;un suivi optimisé du patient,
            certaines de ces données peuvent être transmises à d&apos;autres
            vétérinaires ou laboratoires vétérinaires, avec votre accord. Lors
            de l&apos;identification d&apos;un patient par radiofréquence,
            certaines de vos données sont transférées au fichier national
            d&apos;identification, tel que prévu par les articles D212-63,
            D212-66 et D212-68 du Code Rural et de la Pêche Maritime.
          </p>
          <p>
            Les informations qui vous sont demandées font l&apos;objet, sauf
            opposition justifiée de votre part, d&apos;un enregistrement
            informatique. Seuls les membres du personnel de l&apos;EIRL Gaëlle
            MORAT ont accès à ce fichier : vétérinaires et salariés non
            vétérinaires.
          </p>
          <p>
            Tous les vétérinaires sont tenus au respect du secret professionnel
            dans les conditions établies par la loi (article R242-33 du Code
            Rural et de la Pêche Maritime).
          </p>
          <p>
            Tous les salariés non vétérinaires sont également tenus au secret
            professionnel (article 17 de la Convention collective nationale des
            cabinets et cliniques vétérinaires du 5 juillet 1995. Etendue par
            arrêté du 16 janvier 1996 JORF 24 janvier 1996).
          </p>
          <p>
            Vous disposez d&apos;un droit d&apos;accès, de rectification,
            d&apos;opposition, d&apos;effacement, à la portabilité et à la
            limitation du traitement des informations vous concernant, en en
            effectuant la demande auprès du responsable du fichier, le DV.
            Gaëlle MORAT.
          </p>
          <p>
            <a
              href="https://www.cnil.fr/fr/la-loi-informatique-et-libertes"
              target="_blank"
              rel="noopener noreferrer"
            >
              *Loi n°78-17 du 6 janvier 1978 modifié en 2004 relative à
              l&apos;informatique, aux fichiers et aux libertés
            </a>
          </p>
          Et
          <p>
            <a
              href="https://www.cjoint.com/doc/17_12/GLnmzFxp4tM_rgpd.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              *Règlement UE 2016/679 du 27 avril 2016 relatif à la protection
              des personnes physiques à l&apos;égard du traitement des données à
              caractère personnel et à la libre circulation de ces données, et
              abrogeant la directive 95/46/CE
            </a>{' '}
            (règlement général sur la protection des données)
          </p>
        </Paragraph>
      </Content>
    </LayoutDefault>
  );
};
