import React from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { trackEvent } from '@src/services/analytics';
import styles from './header-link.module.scss';

interface Props {
  to: string;
  label: string;
  open: boolean;
  highlight?: boolean;
}

export const HeaderLink: React.FC<Props> = ({
  to,
  label,
  highlight = false,
  open,
}: Props) => {
  const { pathname } = useLocation();

  let modifiers = '';
  if (open) modifiers += ` ${styles._open}`;
  if (highlight) modifiers += ` ${styles._highlight}`;
  if (pathname === to) modifiers += ` ${styles._active}`;

  return (
    <li className={`${styles.item}${modifiers}`}>
      <Link
        to={to}
        className={styles.link}
        onClick={() => trackEvent('Header', 'Click', label)}
      >
        {label}
      </Link>
    </li>
  );
};
