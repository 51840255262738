import React, { ReactNode } from 'react';
import styles from './subtitle.module.scss';

type Props = {
  hLevel?: number;
  children: ReactNode;
};

export const Subtitle: React.FC<Props> = ({ hLevel, children }) => {
  const TagName = hLevel
    ? (`h${hLevel}` as keyof JSX.IntrinsicElements)
    : 'div';

  return <TagName className={styles.container}>{children}</TagName>;
};
